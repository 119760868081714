@import "@rosszurowski/vanilla/lib/vanilla.css";

@font-face {
  font-family: "Atlas Grotesk";
  font-weight: 400;
  font-style: normal;
  src: url("./fonts/AtlasGrotesk-Regular-Web.woff2") format("woff2"),
    url("./fonts/AtlasGrotesk-Regular-Web.woff") format("woff");
}

html,
body,
main {
  margin: 0;
  padding: 0;
  height: 100%;
}

html {
  /* Chrome Mac Lightmode: rgb(255, 255, 255) */
  /* Chrome Mac Darkmode: rgb(50, 54, 57) */
  /* Safari Mac Darkmode: rgb(57, 56, 57)  */
  /* Safari Mac Lightmode: rgb(206, 205, 205) */
  background-color: rgb(50, 54, 57);
  color: white;
  font-family: "Atlas Grotesk", -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

main {
  display: flex;
  align-items: center;
  justify-content: center;
}

#title {
  font-size: 5vmin;
  letter-spacing: -1.25px;
  opacity: 0;
  text-indent: -0.15em;
}

.loaded #title {
  animation: fade-in 800ms 200ms ease both;
}

#title > svg {
  position: absolute;
  top: 0.2em;
  right: -0.8em;
}

#notice {
  position: fixed;
  bottom: 2rem;
  left: 0;
  right: 0;
  color: rgb(125, 129, 133);
  font-size: 12px;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  opacity: 0;
  pointer-events: none;
  transform: translateY(30%);
  transition-delay: 450ms;
  transition-duration: 500ms;
  transition-property: opacity, transform;
  transition-timing-function: ease;
  width: 100%;
}

.loaded #notice {
  opacity: 1;
  transform: translateY(0px);
}

.loaded #notice.hidden {
  transition-delay: 0ms;
  transition-duration: 200ms;
  opacity: 0;
  transform: translateY(30%);
}

.Node {
  position: absolute;
  width: 128px;
  height: 128px;
  border: 0.5px white solid;
  border-radius: 50%;
  animation: pulse 1000ms ease-out both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(0.05);
  }
  5% {
    opacity: 1;
    transform: scale(0.25);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
